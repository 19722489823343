import { ReactPhotoCollage } from "react-photo-collage";

const setting = {
  width: '100%',
  height: ['250px'],
  layout: [4, 3, 4, 3, 4, 3, 4, 3, 4, 3, 4, 3, 4, 3, 4, 3],
  photos: [
    { source: 'kona.jpg' },
    { source: 'oahu.jpg' },
    { source: 'costa-rica.jpg' },
    { source: 'whale.jpg' },

    { source: 'hotel-del-coronado.jpg' },
    { source: 'lanai.jpg' },
    { source: 'diamondhead.jpg' },
    { source: 'coastal.jpg' },
    { source: 'kauai-highway.jpg' },
    { source: 'jamaica.jpg' },



    { source: 'infitity.jpg' },
    { source: 'st-thomas.jpg' },

    { source: 'maui-sunrise.jpg' },
    { source: 'kittanning.jpg' },
    { source: 'sd-yachts.jpg' },


    { source: 'fly-over.jpg' },
    { source: 'brooklyn-bridge.jpg' },
    { source: 'calumet.jpg' },
    { source: 'big-island.jpg' },
    { source: 'st-kitts.jpg' },
    { source: 'st-pete-pier.jpg' },

    { source: 'honolulu.jpg' },
    { source: 'volcano.jpg' },
    { source: 'chinamans-hat.jpg' },
    { source: 'la.jpg' },

    { source: 'clouds-over-hills.jpg' },
    { source: 'one-world-trade.jpg' },

    { source: 'coronado.jpg' },
    { source: 'st-john.jpg' },
    { source: 'pittsburgh.jpg' },
    { source: 'big-island-beach.jpg' },
    { source: 'indian-rocks-florida.jpg' },
    { source: 'maui-heli.jpg' },
    { source: 'dc.jpg' },
    { source: 'temple.jpg' },
    { source: 'haleakala.jpg' },

    { source: 'smicksburg.jpg' },
    { source: 'lj-cliffs.jpg' },

    { source: 'st-louis-arch.jpg' },
    { source: 'na-pali.jpg' },
    { source: 'kauai-pier.jpg' },
    { source: 'maui-evening.jpg' },
    { source: 'maui-fog.jpg' },

    { source: 'socal.jpg' },
    { source: 'na-pali-top.jpg' },

    { source: 'sd.jpg' },
    { source: 'st-martin.jpg' },
    { source: 'cloudy.jpg' },
    { source: 'sd-overcast.jpg' },
    { source: 'scripts.jpg' },
    { source: 'big-sur.jpg' },
    { source: 'golden-state-bridge.jpg' },
    { source: 'half-moon.jpg' },
    { source: 'mars.jpg' },
    { source: 'yosemite.jpg' },
    { source: 'la-jolla.jpg' }

  ],
  showNumOfRemainingPhotos: true
};




function App() {
  return (
    <ReactPhotoCollage {...setting} />
  );
}

export default App;
